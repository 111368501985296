$color-text: #484848;
$color-accent: #dd5424;
$color-background: #f6f6f6;
$color-header: #ffd32f;
$color-footer: $color-accent;
$font-content: Charter, "Bitstream Charter", serif;
$font-headings: "Jost", "Avenir Next", "Trebuchet MS", sans-serif;

$content-width: 1020px;
$on-laptop: 960px;
$on-palm: 740px;
$spacing-unit: 20px;

@mixin media-query($device) {
  @media (max-width: $device) {
    @content;
  }
}

@import "fonts", "base", "layout";
